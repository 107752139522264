<template>
  <component-frame title="Dialogs">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#modal'">Modal Window</a></li>
          <li><a href="#" v-scroll-to="'#wModal'">Warning Modal</a></li>
        </ul>
      </div>
    </div>

    <h3 id="modal">Modal Window</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
            Launch demo modal
          </button>
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Confirm Changes
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"
                      ><svg class="icon icon-close">
                        <use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg
                    ></span>
                  </button>
                </div>
                <div class="modal-body">
                  You have chosen to do something. Just making sure that you're absolute sure you want to do this.
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                  </button>
                  <button type="button" class="btn btn-primary">
                    Save and Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal"&gt;
  Launch demo modal
&lt;/button&gt;

&lt;div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"&gt;
  &lt;div class="modal-dialog" role="document"&gt;
    &lt;div class="modal-content"&gt;
      &lt;div class="modal-header"&gt;
        &lt;h4 class="modal-title" id="exampleModalLabel"&gt;Confirm Changes&lt;/h4&gt;
        &lt;button type="button" class="close" data-dismiss="modal" aria-label="Close"&gt;
          &lt;span aria-hidden="true"&gt;&lt;svg class="icon icon-close"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/span&gt;
        &lt;/button&gt;
      &lt;/div&gt;
      &lt;div class="modal-body"&gt;
        You have chosen to do something. Just making sure that you're absolute sure you want to do this.
      &lt;/div&gt;
      &lt;div class="modal-footer"&gt;
        &lt;button type="button" class="btn btn-secondary" data-dismiss="modal"&gt;Close&lt;/button&gt;
        &lt;button type="button" class="btn btn-primary"&gt;Save and Continue&lt;/button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Modals are good for confirming important actions or slowing down the users flow if they are about to make an important decision
          </li>
          <li>Important functionality should not be hidden inside a modal</li>
          <li>
            A modal window takes the focus away from what the user is doing, so should generally be used with caution
          </li>
        </ul>
      </div>
    </div>

    <h3 id="wModal">Warning Modal</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#warningModal">
            Launch warning modal
          </button>
          <div class="modal fade" id="warningModal" tabindex="-1" role="dialog" aria-labelledby="warningModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="warningModalLabel">
                    Delete Package
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"
                      ><svg class="icon icon-close">
                        <use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg
                    ></span>
                  </button>
                </div>
                <div class="modal-body">
                  <h1 class="text-danger">
                    <svg class="icon icon-lg icon-warning">
                      <use xlink:href="/icons/symbol-defs.svg#icon-warning"></use>
                    </svg>
                    Warning
                  </h1>
                  <p>
                    This action
                    <span class="text-danger">cannot be undone</span> and all your data will be lost.
                  </p>
                  <p><strong>LTO Application:</strong> 123ABC Mortgage Acme Development v3</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" class="btn btn-danger">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="modal fade" id="warningModal" tabindex="-1" role="dialog" aria-labelledby="warningModalLabel" aria-hidden="true"&gt;
  &lt;div class="modal-dialog" role="document"&gt;
    &lt;div class="modal-content"&gt;
      &lt;div class="modal-header"&gt;
        &lt;button type="button" class="close" data-dismiss="modal" aria-label="Close"&gt;
          &lt;span aria-hidden="true"&gt;&lt;svg class="icon icon-close"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-close"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/span&gt;
        &lt;/button&gt;
        &lt;h4 class="modal-title" id="warningModalLabel"&gt;Delete Package&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="modal-body"&gt;
        &lt;h1 class="text-danger"&gt;&lt;svg class="icon icon-lg icon-warning"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-warning"&gt;&lt;/use&gt;&lt;/svg&gt;
        Warning&lt;/h1&gt;
        &lt;p&gt;This action &lt;span class="text-danger"&gt;cannot be undone&lt;/span&gt; and all your data will be lost.&lt;/p&gt;
        &lt;p&gt;&lt;strong&gt;LTO Application:&lt;/strong&gt; 123ABC Mortgage Acme Development v3&lt;/p&gt;
      &lt;/div&gt;
      &lt;div class="modal-footer"&gt;
        &lt;button type="button" class="btn btn-secondary" data-dismiss="modal"&gt;Cancel&lt;/button&gt;
        &lt;button type="button" class="btn btn-danger"&gt;Delete&lt;/button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Use warning modals for making the user confirm an important action, such as deleting data
          </li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "dialogs",
  data() {
    return {
      title: "Dialogs",
    };
  },
};
</script>
